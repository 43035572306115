import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default function createStore() {
	return new Vuex.Store({
		state: {
			host: '',
			deviceType: 'mobile',
			showDebug: false,
			adsenseConfig: {
				slot_list_pos_4: '',
				slot_list_pos_7: '',
				slot_detail_pos_1: '',
				client: '',
				scriptUrl: '',
				cid: 97,
				ads: 'success',
			},
			firebaseConfig: {
				apiKey: 'AIzaSyCOPcbn2sfxXiBYJQ2drJ6dkCG8FW3K4eg',
				authDomain: 'enetwork-71ebd.firebaseapp.com',
				projectId: 'enetwork-71ebd',
				storageBucket: 'enetwork-71ebd.appspot.com',
				messagingSenderId: '178708903326',
				appId: '1:178708903326:web:91deef4e9bd2b80d6cd28f',
				measurementId: 'G-DEFAULT-KEY',
			},
			themeConfig: {
				backgroundColor: '#FFFFFF',
				leadType: 1, //第一组模块样式
				collectionType: 1, //第二组模块的样式
				adsPos: [4, 7], //第几个新闻的上面！
				landType: 1, //落地页的顶部导航栏样式
				navType: 1, //导航栏
				fooType: 1, //底部
				landAdPos: [1], //0,1,2 内容详情页的顶/中/底，如果中激活时，需要根据landType改变广告位置
				landFeedAdPos: [], //0,2 内容页 feed流的位置  没有中间位置 中间位置根据landFeedInsertAdPos改变
				landFeedInsertAdPos: [2, 5],
				landFeedLeadType: 1,
				landFeedCollectionType: 1,
			},
			domainConfig: {
				title: 'NEWS', //未查询到！@
				icon: '', //
			},
		},
		mutations: {
			setHost(state, host) {
				state.host = host
			},
			setDomain(state, domainConfig) {
				state.domainConfig = domainConfig
			},
			setDeviceType(state, deviceType) {
				state.deviceType = deviceType
			},
			setAdsense(state, adsenseConfig) {
				state.adsenseConfig = adsenseConfig
			},
			setFirebase(state, firebaseConfig) {
				state.firebaseConfig = firebaseConfig
			},
			setTheme(state, themeConfig) {
				state.themeConfig = themeConfig
			},
			setDebug(state, showDebug) {
				state.showDebug = showDebug
			},
		},
		getters: {
			themeConfig: (state) => state.themeConfig,
			domainConfig: (state) => state.domainConfig,
			showDebug: (state) => state.showDebug,
			deviceType: (state) => state.deviceType,
			host: (state) => state.host,
			adsenseConfig: (state) => state.adsenseConfig,
		},
	})
}
